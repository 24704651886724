import { Application, AttributeObserver, type ControllerConstructor } from "stimulus"

function useLazyLoading (application: Application, modulesGlob: ReturnType<ImportMeta['glob']>) {
  function getValidControllerModulesFromGlob (modules: ReturnType<ImportMeta['glob']>) {
    return Object.entries(modules).reduce((memo, [name, module]) => {
      const identifier = identifierForGlobKey(name)
      if (identifier) memo[identifier] = module as () => Promise<{ default: ControllerConstructor }>
  
      return memo
    }, {} as Record<string, () => Promise<{ default: ControllerConstructor }>>)
  }
  
  const CONTROLLER_FILENAME_REGEX = /^(?:.*?(?:controllers|components)\/|\.?\.\/)?(.+)(?:[/_-]controller\..+?)$/
  
  function identifierForGlobKey (key: string): string | undefined {
    const logicalName = (key.match(CONTROLLER_FILENAME_REGEX) || [])[1]
    if (logicalName) return logicalName.replace(/_/g, '-').replace(/\//g, '--')
  }

  const modules = getValidControllerModulesFromGlob(modulesGlob)

  const loadingControllers = new Set<string>()

  const loadController = async (name: string) => {
    if (loadingControllers.has(name)) return
    if (application.router.modulesByIdentifier.has(name)) return

    const module = modules[name]
    if (!module) return

    loadingControllers.add(name)

    const definition = await module()
    if (definition) {
      application.register(name, definition.default ?? definition)
    }

    loadingControllers.delete(name)
  }

  const loadControllers = (element: Element) => {
    if (element.nodeType !== Node.ELEMENT_NODE) return

    const names = element.getAttribute(application.schema.controllerAttribute)?.split(/\s+/) ?? []
    for (const name of names) {
      void loadController(name)
    }
  }

  new AttributeObserver(
    application.element,
    application.schema.controllerAttribute,
    {
      elementMatchedAttribute: loadControllers,
      elementAttributeValueChanged: loadControllers
    }
  ).start()
}

// Actually init stimulus
useLazyLoading(
  Application.start(),
  import.meta.glob('../controllers/**/*_controller.ts')
)